import React from 'react';
import IndexView from 'views/IndexView';
import useCookieFromClickID from 'hooks/useCookieFromClickID';

const IndexPage = (): JSX.Element => {
  const { clickID } = useCookieFromClickID();
  console.log('Click ID:', clickID);

  return (
    <>
      <IndexView />
    </>
  );
};

export default IndexPage;
